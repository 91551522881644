import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from "@material-ui/core/TextField"
import Divider from "@material-ui/core/Divider"
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { useState, useEffect, createRef } from 'react'
import axios from "axios"
import Api from "./api"
import Session from "./api/Session"
import Collapse from '@material-ui/core/Collapse'
import ReCAPTCHA from "react-google-recaptcha"
import DropdownTextField from "./DropdownTextField"

function popupWindow(url: string, windowName: string, win:Window, w:number, h:number) {
    const y = 30 + win?.top?.outerHeight / 2 + win?.top?.screenY - ( h / 2);
    const x = 10 + win?.top?.outerWidth / 2 + win?.top?.screenX - ( w / 2);
    return win.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
}
const dev = window.localStorage.dev === "true"
const itmpUrl = dev ? "http://localhost:3001" : "https://itmp.hu"

const recaptchaRef = createRef<any>()

const Register = (props: {open: boolean, session: Session|null, onClose: any}) => {

	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	//const [phone, setPhone] = useState('')
	const [zip, setZip] = useState('')
	const [city, setCity] = useState('')
	const [teacher, setTeacher] = useState(true)
	const [school, setSchool] = useState('')

	const [schoolOptions, setSchoolOptions] = useState<Array<{id: number, label: string}>>([])
	const [selectSchoolError, setSelectSchoolError] = useState(false)


	const [emailTaken, setEmailTaken] = useState(false)
	const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false)
	const [newsletter, setNewsletter] = useState(false)

	const [step, setStep] = useState(0)

	const [itmpError, setItmpError] = useState<false|"alreadyRegistered"|"other">(false)
	const [regError, setRegError] = useState(false)
	const [alreadyRegistered, setAlreadyRegistered] = useState(false)

	const [recaptcha, setRecaptcha] = useState<string|null>(null)

	useEffect(()=>{
		if (zip.length>=4){
			axios.get("https://itmp.hu/api/util/zip?q=" + zip).then(res=>{
				setCity(res.data.name)
			})
		}
	}, [zip])

	useEffect(() => {
		setSuccess(false)
	}, [props.session?.id])

	useEffect(() => {
		if (!schoolOptions.length) {
			axios.get("https://itmp.hu/api/schools").then(res=>{
				setSchoolOptions(res.data.map((school: {id: number, name: string, city: string}) => {return {id: school.id, label: `${school.name} (${school.city})`}}))
			})
		}
	}, [])

	useEffect(() => {
		setSchool('')
	}, [teacher])

	useEffect(() => {
		if (recaptcha) {
			

			let reg = postRegistration()
			const itmpRegUrl = itmpUrl + "/api/user/create"

			let itmpRegPromise = new Promise<void>((resolve, reject) => {


				axios.post(itmpUrl + "/api/user/checkemailtaken", {email: email}).then(res=>{
					if (res.data.taken) {
						setItmpError("alreadyRegistered")
						resolve()
					} else {
						axios.post(itmpRegUrl, {
							firstName,
							lastName,
							email,
							zip,
							city,
							schoolId: Number(school),
							recaptcha
						}).then(() => {
							setKlubSuccess(true)
						}).catch((data) => {
							console.log(data)
							setItmpError("other")
						}).finally(() => {
							resolve()
						})
					}
				})
			})

			Promise.all([reg, itmpRegPromise]).finally(() => {
				setSuccess(true)
				setStep(0)
			})
		}
	}, [recaptcha]) // eslint-disable-line react-hooks/exhaustive-deps

	const postRegistration = () => {
		setAlreadyRegistered(false)
		let schoolName = teacher ? schoolOptions.find(s => s.id === Number(school))!.label : school
		return Api.Session.register(props?.session?.id as number, {
			firstName,
			lastName,
			email,
			zip,
			city,
			school: schoolName,
			teacher,
			newsletter
		}).then(data => {
			if (data?.alreadyRegistered) {
				setAlreadyRegistered(true)
			}
		})
	}

	const [success, setSuccess] = useState(false)
	const [klubSuccess, setKlubSuccess] = useState(false)

	return (
		<Dialog open={props.open} onClose={props.onClose} maxWidth="xs" fullWidth scroll="body">
			<Dialog open={itmpError != false}>
				<DialogTitle>ITMP Klub jelentkezés</DialogTitle>
				<DialogContent>
					{ itmpError === "alreadyRegistered" ?  
						<Typography variant="body1">
							Már ITMP Klub tag vagy ezzel az e-mail címmel.
							Reméljük, az infoTanáriumot is hasznosnak fogod találni, az eseményhez történő csatlakozási linket és információkat elküldtük a megadott e-mail címre.
						</Typography> : 
						<>
							<Typography variant="body1">
							Sajnos nem sikerült az ITMP Klub jelentkezésedet elvégezni.
						</Typography>
						<Typography variant="body1">
							Az <a className="link" href="https://itmp.hu/jelentkezes" target="_blank" rel="noopener noreferrer">itmp.hu/jelentkezes</a> oldalon megpróbálhatod újra a jelentkezést.
						</Typography>
						</>
					}
					
					<Button variant="contained" color="primary" style={{margin: '20px 0'}} onClick={()=>setItmpError(false)}>Bezárás</Button>
				</DialogContent>
			</Dialog>
			<Dialog open={regError}>
				<DialogTitle>Hiba történt</DialogTitle>
				<DialogContent>
					<Typography variant="body1">
						Sajnos nem sikerült a regisztrációd elküldése. Kérjük, probálkozz újra később.
					</Typography>
					<Button variant="contained" color="primary" style={{margin: '20px 0'}} onClick={()=>setRegError(false)}>Bezárás</Button>
				</DialogContent>
			</Dialog>
			<DialogContent style={{paddingBottom: '20px'}}>
				<Collapse in={step === 0}>
				{ !success ? 
				<>
				{ props.session?.klubRegistration ?
					<>
						<Typography variant="h6" gutterBottom>Gyors regisztráció ITMP Klub fiókkal</Typography>
						<Typography variant="subtitle1" gutterBottom>
							Ha már rendelkezel ITMP Klub fiókkal, akkor pillanatok alatt elvégezheted az eseményre való regisztrációt.
						</Typography>
						<Button onClick={() => {
							let dev = window.localStorage.dev === "true"
							let infotanariumUrl = dev ? "http://localhost" : window.origin
							let openUrl = `${itmpUrl}/auth?post=${infotanariumUrl}/api/session/${props?.session?.id}/register`
							popupWindow(openUrl, 'ITMP Klub', window, 440, 645)

							window.addEventListener("message", (event) => {
								console.log("event", event)
								if (event.origin !== itmpUrl) return
								if (event.data === "success") {
									setSuccess(true)
								}
							}, false)


						}} color="primary" variant="contained" fullWidth size="large">Folytatás ITMP Klub fiókkal</Button>
					</>
				 : null }
				
				{ props.session?.klubRegistration && props.session.plainRegistration ?
					<div className="orDivider">
						<Divider />
						<div className="or">vagy</div>
					</div>
					: null
				}
				{ props.session?.plainRegistration ?
					<>	
						<Typography variant="h6" gutterBottom>Regisztráció</Typography>
						<form onSubmit={async (e) => {
							e.preventDefault()
							if (!teacher && step === 0) {
								await postRegistration()
								setSuccess(true)
							} else {
								if (!school) return setSelectSchoolError(true)
								setStep(step + 1)
							}
						}}>
							<Grid container spacing={2}>
							<Grid item xs={6}> 
											<TextField 
												label="Vezetéknév"
												variant="outlined"
												margin="none"
												value={lastName}
												onChange={(e)=>{setLastName(e.target.value)}}
												fullWidth
												required
												name="lname"
												autoComplete="family-name"
											/>
										</Grid>
										<Grid item xs={6}>
											<TextField 
												label="Utónév"
												variant="outlined"
												margin="none"
												value={firstName}
												onChange={(e)=>{setFirstName(e.target.value)}}
												fullWidth
												required
												name="fname"
												autoComplete="given-name"
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField 
												label="Email cím"
												variant="outlined"
												margin="none"
												value={email}
												onChange={(e)=>{setEmail(e.target.value); setEmailTaken(false)}}
												fullWidth
												type="email"
												required
												name="email"
												autoComplete="email"
												error={emailTaken}
												helperText={(emailTaken) ? "Ez az email cím már foglalt." : null}
											/>
										</Grid>
										{/* <Grid item xs={12}>
											<TextField 
												label="Telefonszám"
												variant="outlined"
												margin="none"
												value={phone}
												onChange={(e)=>{setPhone(e.target.value)}}
												fullWidth
												type="tel"
												required
												name="phone"
												autoComplete="tel"
											/>
										</Grid> */}
										<Grid item xs={5}> 
											<TextField 
												label="Irányítószám"
												variant="outlined"
												margin="none"
												value={zip}
												onChange={(e)=>{setZip(e.target.value)}}
												fullWidth
												inputProps={{inputMode: "numeric"}}
												required
												name="zip"
												autoComplete="postal-code"
												type="number"
												onKeyDown={(e)=>{if (e.key==='ArrowUp'||e.key==='ArrowDown') e.preventDefault()}}
											/>
										</Grid>
										<Grid item xs={7}>
											<TextField 
												label="Település"
												variant="outlined"
												margin="none"
												value={city}
												onChange={(e)=>{setCity(e.target.value)}}
												fullWidth
												required
												name="city"
												autoComplete="address-level2"
											/>
										</Grid>
										<Grid item xs={12}>
											<FormControlLabel
												control={
													<Checkbox checked={teacher} onChange={()=>setTeacher(!teacher)} />
												}
												label="Informatika tanár vagyok"
											/>
										</Grid>
										<Grid item xs={12}>
											{teacher ?
												<DropdownTextField
													label="Iskola"
													options={schoolOptions}
													required
													onChange={(value: string)=>{setSchool(value); setSelectSchoolError(false)}}
													error={selectSchoolError}
													helperText={selectSchoolError ? 'Kérjük, válassz iskolát a listából' : null}
												/>
												: 
												<TextField 
													label="Munkahely"
													variant="outlined"
													margin="none"
													value={school}
													onChange={(e)=>{setSchool(e.target.value)}}
													fullWidth
													required={teacher}
													name="school"
												/>
											}
										</Grid>
										<Grid item xs={12}>
											<FormControlLabel
												control={
													<Checkbox checked={newsletter} onChange={()=>setNewsletter(!newsletter)} />
												}
												label="Szeretnék az ITMP Klub tevékenységéről értesítést kapni"
											/>
										</Grid>
										<Grid item xs={12}>
											<FormControlLabel
												control={
													<Checkbox required checked={privacyPolicyChecked} onChange={()=>setPrivacyPolicyChecked(!privacyPolicyChecked)} value="privacyPolicy" />
												}
												label={<span>Elolvastam és elfogadom az <a className="link" href="/adatkezelesi_tajekoztato_infoTanarium.pdf" target="_blank" rel="noopener">Adatkezelési Tájékoztató</a>ban foglaltakat.<sup>*</sup></span>}
											/>
										</Grid>
								<Grid item xs={12}>
									<Button type="submit" color="secondary" variant="contained" fullWidth size="large">
										{ teacher ? "Tovább" : "Regisztráció"}
									</Button>
								</Grid>
							</Grid>
						</form>
					</> : null }
				</> : <>

					
					<svg width="80px" height="80px" viewBox="0 0 400 400" style={{display: 'block', margin: '10px auto 20px', fill: '#367f8a'}}>
						<g>
							<path d="M199.996,0C89.713,0,0,89.72,0,200s89.713,200,199.996,200S400,310.28,400,200S310.279,0,199.996,0z M199.996,373.77
								C104.18,373.77,26.23,295.816,26.23,200c0-95.817,77.949-173.769,173.766-173.769c95.817,0,173.771,77.953,173.771,173.769
								C373.768,295.816,295.812,373.77,199.996,373.77z"/>
							<path d="M272.406,134.526L169.275,237.652l-41.689-41.68c-5.123-5.117-13.422-5.12-18.545,0.003
								c-5.125,5.125-5.125,13.425,0,18.548l50.963,50.955c2.561,2.558,5.916,3.838,9.271,3.838s6.719-1.28,9.279-3.842
								c0.008-0.011,0.014-0.022,0.027-0.035L290.95,153.071c5.125-5.12,5.125-13.426,0-18.546
								C285.828,129.402,277.523,129.402,272.406,134.526z"/>
						</g>
					</svg>
					<Typography variant="h6" align="center" style={{margin: '10px 0 20px 0'}}>
						{klubSuccess ? 
							  "Sikeres regisztráció és jelentkezés!"
							: "Sikeres regisztráció!"
						}
					</Typography>

					{ alreadyRegistered ? 
						<>
							<Typography variant="body1" gutterBottom align="center" style={{margin: '20px 0'}}>
								Ezzel az e-mail címmel már jelentkeztél erre az eseményünkre.
							</Typography>
							{klubSuccess ? 
								<Typography variant="body1" align="center" style={{marginBottom: '20px'}}>
									<b>Ne felejtsd el véglegesíteni az ITMP Klub jelentkezésedet!</b><br/> Az ehhez szükséges információkat e-mailben elküldtük számodra.
								</Typography>	
							: null }
							<Typography variant="body1" gutterBottom align="center">
								Az eseménybe való becsatlakozáshoz szükséges linket újra elküldtük a megadott e-mail címre.
							</Typography>
						</> : 
						<>
							<Typography variant="body1" gutterBottom align="center" style={{margin: '20px 0'}}>
							{ klubSuccess ? 
								"Köszönjük, hogy regisztráltál erre az eseményre és hogy jelentkeztél a Klubba!"
								: "Köszönjük, hogy regisztráltál erre az eseményre."
							}
							</Typography>
							{
								klubSuccess ? <>
									<Typography variant="body1" align="center" style={{marginBottom: '20px'}}>
										<b>Ne felejtsd el véglegesíteni az ITMP Klub jelentkezésedet!</b><br/> Az ehhez szükséges információkat e-mailben elküldtük számodra.
									</Typography>
									<Typography variant="body1" gutterBottom align="center">
										Az infoTanárium eseménybe való becsatlakozáshoz szükséges linket egy másik e-mailben küldtük meg számodra.
									</Typography>
								</> : 
								<Typography variant="body1" gutterBottom align="center">
									Az eseménybe való becsatlakozáshoz szükséges linket e-mailben fogod megkapni.
								</Typography>
							}
						</>
					}
					
					<Button color="primary" fullWidth variant="contained" style={{marginTop: '20px'}} onClick={() => props.onClose()}>Bezárás</Button>
				</>}
				</Collapse>
				<Collapse in={step > 0}>
					{ teacher && step > 0 ?
						<ReCAPTCHA onChange={(token)=>setRecaptcha(token)} ref={recaptchaRef as any} sitekey="6LdDGrAUAAAAABeGI_8gfOFrmxXsancRH6C5Khjy" size="invisible"/>
					: null }
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="subtitle2" align="center" style={{fontSize: '13px'}}>
								Regisztrációd véglegesítése előtt:
							</Typography>
						</Grid>
						<Grid item xs={12} style={{textAlign: 'center'}}>
							<img src="/tanar.png" alt="" style={{height: '280px', margin: '-20px 0 0 0'}}/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6" align="center" style={{fontSize: '24px'}}>Jelentkezz az ITMP Klubba is!</Typography>
						</Grid>
						{/* <Grid item xs={12}>
							<Typography variant="body1" align="center">
								Az ITMP Klub tagjai olyan informatika szakos tanárok, akik tenni akarnak folyamatos szakmai fejlődésükért. 
							</Typography>
						</Grid> */}
						<Grid item xs={12}>
							<Typography variant="body1" align="center">
								A klubtagsággal számos ingyenes szolgáltatást vehetsz igénybe, melyek hozzájárulnak a szakmai fejlődésedhez. 
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1" align="center">
								<a className="link underline" href="https://itmp.hu/tanar-vagyok" target="_blank" rel="noopener noreferrer">
									Tudj meg többet a Klubbról
								</a>
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="subtitle2" align="center" style={{fontSize: '13px'}}>
								Az imént megadott adataiddal máris el tudjuk készíteni a fiókodat! 
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Button onClick={() => {
								recaptchaRef.current.execute()
							}} variant="contained" size="large" color="primary" fullWidth>
								Jelentkezem az ITMP Klubba is!
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Button style={{textTransform: 'none'}} variant="outlined" size="large" color="secondary" fullWidth onClick={async () => {
								await postRegistration()
								setSuccess(true)
								setStep(0)
							}}>
								Csak az eseményre szeretnék regisztrálni
							</Button>
						</Grid>
					</Grid>
					
					
				</Collapse>
			</DialogContent>
			
		</Dialog>
	)
}

export default Register