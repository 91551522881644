import React from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import FuzzySearch from 'fuzzy-search'


const DropdownTextField = (props)=>{

	const [anchorEl, setAnchorEl] = React.useState(null)
	const [textValue, setTextValue] = React.useState(props.defaultTextValue)
	const [open, setOpen] = React.useState(false)
	const [selectedIndex, setSelectedIndex] = React.useState(0)
	const [shownOptions, setShownOptions] = React.useState([])
	const [shownOptionsLength, setShownOptionsLength] = React.useState(0)

	

	const onChange = (e)=>{
		setTextValue(e.target.value)
		setAnchorEl(e.currentTarget)
		let inputValue = e.target.value
		
		setSelectedIndex(0)

		props.onChange(null)
		if (inputValue.length>0) {
			let searcher = new FuzzySearch(props.options, ['label'], {sort: true})
			let result = searcher.search(inputValue);
			setOpen(true)
			setShownOptions(result.slice(0,4))
			setShownOptionsLength(result.length)
		}
		else {
			setOpen(false)
			setShownOptions([])
			setShownOptionsLength(0)
		}
	}

	const onKeyDown = (e)=>{
		if (e.key==='ArrowDown'){
			e.preventDefault()
			if (selectedIndex<(shownOptions.length-1)) setSelectedIndex(selectedIndex+1)
			return
		}
		if (e.key==='ArrowUp'){
			e.preventDefault()
			if (selectedIndex>0) setSelectedIndex(selectedIndex-1)
			return
		}
		if (e.key==='Enter') {
			e.preventDefault()
			if (shownOptions.length){
				setTextValue(shownOptions[selectedIndex].label)
				props.onChange(shownOptions[selectedIndex].id)
				setOpen(false)
				e.target.blur()
			}
			return
		}
	}

	return (
		<>
			<TextField
				fullWidth
				variant="outlined"
				margin="none"
				label={props.label}
				onChange={onChange}
				onKeyDown={onKeyDown}
				onFocus={(e)=>{
					if (e.target.value.length>0) {
						setOpen(true)
						e.target.select()
					}
					onChange(e)
				}}
				onBlur={()=>{
					if (shownOptions.length){
						setTextValue(shownOptions[selectedIndex].label)
						props.onChange(shownOptions[selectedIndex].id)
					}
					setTimeout(()=>{
						setOpen(false)
					}, 200)
				}}
				value={textValue}
				required={props.required}
				error={props.error}
				helperText={props.helperText}
			/>
			<Popper placement="bottom-start" open={open} anchorEl={anchorEl} style={{zIndex: '10000', maxWidth: '100vw', minWidth: '394px', maxHeight: '245px', overflowY: 'auto'}}>
				<Paper style={{border: '1px solid #000'}}>	
					{shownOptions.map((option, key)=>{
						return (
							<MenuItem key={key} selected={key===selectedIndex} onClick={()=>{
								//anchorEl.focus()
								props.onChange(option.id)
								setTextValue(option.label)
								setOpen(false)
							}}>
								{option.label}
							</MenuItem>
						)
					})}
					{shownOptionsLength > 5 ? 
						<MenuItem disabled selected={false} onClick={()=>{
							setOpen(true)
						}}>
							További {shownOptionsLength} találat (Gépeljen tovább a találatok szűkítéshez)
						</MenuItem>
						: null
					}
					{shownOptions.length === 0 ? 
						<MenuItem selected={0===selectedIndex} onClick={()=>{
							props.onChange(null)
						}}>
							Nincs találat
						</MenuItem>
						: null
					}
				</Paper>
			</Popper>
		</>
	)
}

export default DropdownTextField;