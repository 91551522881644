import { Editor } from '@tinymce/tinymce-react'

const TextEditor = (props)=>{
	return (
		<div className={"editor " + props.className}>
			<Editor
				apiKey="6p1t1pu4ql5k2vb3txvjeqaxtbwlkfpz9n4xnsxriuv6vvie"
				/* tinymceScriptSrc='/tinymce.min.js' */
				initialValue={props.initialValue}
				inline={props.inline}
				init={{
					menubar: false,
					allow_unsafe_link_target: true,
					relative_urls : false,
					remove_script_host : false,
					document_base_url : window.location.origin,
					content_style: (props.contentStyle) ? props.contentStyle : "",
					plugins: [
						'advlist autolink lists link image charmap print preview anchor',
						'searchreplace visualblocks code fullscreen',
						'insertdatetime media table paste code help wordcount'
					],
					fontsize_formats: "8px 10px 12px 14px 16px 18px 24px 30px 36px",
					toolbar:
					`undo redo | fontsizeselect | bold italic forecolor | 
					alignleft aligncenter alignright alignjustify | 
					bullist numlist outdent indent | removeformat | code `,
					...props.init
				}}
				value={props.value}
				onEditorChange={props.onChange?props.onChange:()=>{}}
				onBlur={props.onBlur?props.onBlur:()=>{}}
			/>
		</div>
	)
}

export default TextEditor