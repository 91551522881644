import { AxiosInstance } from "axios"
import BasicResource from "./BasicResource"
import Session from "./Session"

class AdminResource extends BasicResource {
	constructor(axiosClient: AxiosInstance) {
		super("/admin", axiosClient)
	}

	public async getSessions() {
		return this.get("session")
	}
	public async getSession(id: number) {
		return this.get(this.path("session", String(id)))
	}
	public async getSessionStats(id: number) : Promise<{registrationsCount: number, itmpRegistrationsCount: number}> {
		return this.get(this.path("session", String(id), "stats"))
	}
	public async createSession() {
		return this.post("session")
	}
	public async saveSession(id: number, data: Session) {
		return this.put(this.path("session", String(id)), data)
	}
	public async deleteSession(id: number) {
		return this.delete(this.path("session", String(id)))
	}
	public async login(password: string) {
		return this.post("login", {password})
	}
	public async exportSession(id: number) {
		return this.axiosClient.get(this.path("session", String(id), "export"), {responseType: 'blob'})
	}
	public async uploadFile(sessionId: number, historyVideoId: number, data: FormData) {
		return this.axiosClient.post(this.path("session", String(sessionId), "upload", String(historyVideoId),), data, {headers: {'Content-Type': 'multipart/form-data'}})
	}
	public async removeFile(fileId: number) {
		return this.delete(this.path("file", String(fileId)))
	}
}

export default AdminResource