import { AxiosInstance } from "axios"
import BasicResource from "./BasicResource"
import Registration from "./Registration"
import Session from "./Session"

class SessionResource extends BasicResource {
	constructor(axiosClient: AxiosInstance) {
		super("/session", axiosClient)
	}
	public getLive(code: string): Promise<Session> {
		if (code) return this.get("/live?" + code)
		else return this.get("/live")
	}
	public getArchive(): Promise<Session[]> {
		return this.get("/archive")
	}
	public getUpcoming(): Promise<Session[]> {
		return this.get("/upcoming")
	}
	public register(sessionId: number, data: Registration) {
		return this.post(this.path(String(sessionId), "/register"), data)
	}
	public getSession(id: number) {
		return this.get(String(id))
	}
}

export default SessionResource