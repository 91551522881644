import { useState, useEffect } from 'react'
import Admin from './Admin'
import { Switch, Route, Link, useLocation, Redirect, useRouteMatch, useHistory } from "react-router-dom"
import Button from '@material-ui/core/Button'
import SessionPlayer from "./SessionPlayer"
import LiveTv from '@material-ui/icons/LiveTv'
import Launch from '@material-ui/icons/Launch'
import './App.scss'

//import getVideo from './getVideo.js'
import Api from './api'
import Session from './api/Session'

import Esemenyek from "./Esemenyek"

export function Base() {

	const [live, setLive] = useState(false)
	const [initLoading, setInitLoading] = useState(true)
	const [thankYouNote, setThankYouNote] = useState(false)
	const [session, setSession] = useState<Session | null>(null)
	
	const [directClick, setDirectClick] = useState(false)
	//const [code, setCode] = useState<string|null>(null)

	const location: any = useLocation()
	const history = useHistory()

	const getLiveSession = () => {
		let code = location.search.replace("?", "") || window.localStorage.code || null
		Api.Session.getLive(code).then(video => {
			setSession(video)
			setLive(!video.noLive)
			if (initLoading) setInitLoading(false)
			
			if (code) window.localStorage.setItem("code", code)
			
			if (!video.error) {
				if (video.id !== Number(window.localStorage.lastAttendanceSessionId)) {
					Api.emitEvent("attendance", {code, sessionId: video.id})
					window.localStorage.setItem("lastAttendanceSessionId", String(video.id))
				}
			} else {
				window.sessionStorage.removeItem("watching")
			}
		})
	}

	
	useEffect(() => {
		getLiveSession()

		Api.subscribeEvent("live:change", (liveSession: Session) => {
			console.log("live:change")
			setSession(liveSession)
		})
		Api.subscribeEvent("live:start", (liveSession: Session) => {
			console.log("live:start", liveSession)
			setSession(liveSession)
			window.setTimeout(() => setLive(true), 200)
			if (!liveSession.error) {
				if (liveSession.id !== Number(window.localStorage.lastAttendanceSessionId)) {
					Api.emitEvent("attendance", {code: window.localStorage.code, sessionId: liveSession.id})
					window.localStorage.setItem("lastAttendanceSessionId", String(liveSession.id))
				}
			}
		})
		Api.subscribeEvent("live:end", () => {
			console.log("live:end")
			if (window.sessionStorage.watching) setThankYouNote(true)
			setSession(null)
			setLive(false)
		})
		Api.subscribeEvent("connect", () => {
			let code = location.search.replace("?", "") || window.localStorage.code
			if (code) Api.emitEvent("code:set", code)
		})
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

		

	useEffect(() => {
		if (location.state?.directClick) {
			setDirectClick(true)
			history.replace('/', {...location.state, directClick: false})
		}
	}, [location.state?.directClick]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
	<Switch>
		<Route path="/admin">
			<Admin />
		</Route>
		<Route>
			<div className="App">
				<div className="header">
					<img src="/logo.png" className="logo" alt="infoTanárium" />
					{ live ? 
						<Link to={{pathname: "/", state: {directClick: true}}}>
							<Button className="button" variant="contained" color="primary"><LiveTv className="icon left" />ÉLŐ adás</Button>
						</Link>
					: null }
					<Link to="/esemenyek">
						<Button className={`button ${location.pathname.includes("/esemenyek") || (!live && location.pathname === "/") ? 'active' : ''}`} variant="contained" color="secondary">Közelgő események</Button>
					</Link>
					<Link to="/archivum">
						<Button className={`button ${location.pathname.includes("/archivum") ? 'active' : ''}`} variant="contained" color="secondary">Archívum</Button>
					</Link>
					<Button href="https://itmp.hu/klub" target="_blank" rel="noopener noreferrer" className="button" variant="contained" color="secondary">ITMP Klub <Launch className="icon"/></Button>
				</div>
				<Switch>
					<Route path="/esemenyek">
						<Esemenyek upcoming/>
					</Route>
					<Route path="/archivum" exact>
						<Esemenyek archive />
					</Route>
					<Route path="/archivum/:id">
						<ArchivePlayer />
					</Route>
					<Route>
						{ !live && !initLoading ? <Esemenyek upcoming thankYouNote={thankYouNote} /> : null }
						{ live ? 
								!session?.error || (session?.error && directClick) ?
									<SessionPlayer session={session} />
									: <Redirect to="/esemenyek" />
							: null
						}
					</Route>
				</Switch>
				<div className="logos">
					<img src="/netacad.png" className="netacad-logo" alt="Cisco Networking Academy" />
					<img src="/http.png" className="http-logo" alt="HTTP Alapítvány" />
					<img src="/klub-logo.png" className="klub-logo" alt="ITMP Klub" />
				</div>
			</div>
		</Route>
	</Switch>)
}

const ArchivePlayer = (props: {}) => {
	const match = useRouteMatch<any>()
	const [session, setSession] = useState<Session | null>(null)

	useEffect(() => {
		Api.Session.getSession(Number(match.params.id)).then((session: Session) => {
			session.HistoryVideos = session.HistoryVideos.sort((a, b) => a.order - b.order)
			//console.log(session)
			setSession(session)
		})
	}, [match.params?.id])

	return (
		<SessionPlayer session={session} />
	)
}