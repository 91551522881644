import { AxiosInstance } from "axios"
import urlJoin from "url-join"

class BasicResource{
	constructor(private basePath:string, protected axiosClient: AxiosInstance){ }

	protected async get(path: string) {
		return this.request("get", path)
	}

	protected async post(path: string, data: Object = {}) {
		return this.request("post", path, data)
	}

	protected async put(path: string, data: Object = {}) {
		return this.request("put", path, data)
	}

	protected async delete(path: string) {
		return this.request("delete", path)
	}

	protected async request(method: "get" | "post" | "put" | "delete", path: string, data: Object = {}){
		let result = await this.axiosClient.request({
			url: path.startsWith(this.basePath) ? path : this.path(path),
			method,
			data
		})
		return result.data
	}

	protected path(...paths: string[]) {
		return urlJoin(this.basePath, ...paths)
	}
}

export default BasicResource